import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  setDoc,
  doc,
  enableIndexedDbPersistence,
} from 'firebase/firestore';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBP1OC453X5tRvJSvgLXNXPYAtgNV-DUAA',
  authDomain: 'pomodato.com',
  projectId: 'pomodato',
  storageBucket: 'pomodato.appspot.com',
  messagingSenderId: '146721314223',
  appId: '1:146721314223:web:4cf91b41b5b7a4e2834b5a',
  measurementId: 'G-85RYJ9GNMV',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
isSupported().then((supported) => {
  if (supported) {
    const analytics = getAnalytics(app);
  }
});

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open
  } else if (err.code === 'unimplemented') {
    // Browsser not supported
  }
});

const formatError = (error) => {
  return (
    error
      .toString()
      .match(/\(([^)]+)\)/)[1]
      .replace(/auth\/|-/g, ' ')
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ') + '.'
  );
};

const logInWithGoogle = async () => {
  const googleProvider = new GoogleAuthProvider();

  try {
    const googleAuthData = await signInWithPopup(auth, googleProvider);
    const googleUser = googleAuthData.user;

    const googleUserQuery = query(
      collection(db, 'users'),
      where('uid', '==', googleUser.uid)
    );
    const googleUserDocs = await getDocs(googleUserQuery);

    if (googleUserDocs.docs.length === 0) {
      const userDocRef = doc(collection(db, 'users'), googleUser.uid);
      await setDoc(
        userDocRef,
        {
          uid: googleUser.uid,
          name: googleUser.displayName,
          authProvider: 'google',
          email: googleUser.email,
        },
        { merge: true }
      );
    }
  } catch (err) {
    alert(formatError(err));
  }
};

const logInWithEmailAndPassword = async (email, password, setIsSubmitting) => {
  try {
    setIsSubmitting(true);

    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    alert(formatError(err));
  }
};

const registerWithEmailAndPassword = async (
  name,
  email,
  password,
  setIsSubmitting
) => {
  const formatStrTitleCaseRemoveSlash = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ')
      .replace(/\\|\//g, '');
  };

  try {
    setIsSubmitting(true);

    const emailAuthData = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const emailUser = emailAuthData.user;

    const userDocRef = doc(collection(db, 'users'), emailUser.uid);
    await setDoc(
      userDocRef,
      {
        uid: emailUser.uid,
        name: formatStrTitleCaseRemoveSlash(name),
        authProvider: 'local',
        email,
      },
      { merge: true }
    );
  } catch (err) {
    alert(formatError(err));
  }
};

const sendPasswordReset = async (email, setIsSubmitting) => {
  try {
    setIsSubmitting(true);
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    alert(formatError(err));
  }
};

const logout = () => {
  signOut(auth);
  try {
    indexedDB.deleteDatabase('firebaseLocalStorageDb');
  } catch (error) {}
  window.location.reload(false);
};

export {
  auth,
  logInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};

export default db;
